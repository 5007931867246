

















































import Vue from 'vue';
import BaseVue from './Base.vue';
import LoadingVue from '@/components/Loading.vue';
import {fetchTeamMembers, updateParseObject, sendDebugNotification} from '../services/parseQueries';

export default Vue.extend({
    name: 'TeamMembers',
    components: {LoadingVue},
    extends: BaseVue,
    data() {
        return {
            loading: false,
            searchTerm: '',
            teamMembers: [],
            errorMessage: ''
        };
    },
    mounted() {
        if (this.$route.query.q) {
            this.searchTerm = this.$route.query.q.toString();
            this.searchTeamMembers();
        }
    },
    methods: {
        search() {
            this.searchTerm = this.searchTerm.trim();
            if (!this.searchTerm) {
                return false;
            }
            this.$router.replace({path: 'teamMembers', query: {q: this.searchTerm}});
            this.searchTeamMembers();
        },

        async searchTeamMembers() {
            this.errorMessage = '';
            this.loading = true;
            try {
                this.teamMembers = await fetchTeamMembers(this.searchTerm);
            } catch (error) {
                this.errorMessage = error.response ? error.response.data.error.message : error.message;
                this.teamMembers = [];
            } finally {
                this.loading = false;
            }
        },

        async sendNotification(teamMemberId: string) {
            const select: HTMLSelectElement[] = this.$refs[`notification-type-${teamMemberId}`] as HTMLSelectElement[];
            const notificationType: string | null = select[0] ? select[0].value : null;
            if (notificationType) {
                try {
                    await sendDebugNotification({teamMemberId, notificationType});
                } catch (error) {
                    this.errorMessage = error.response.data.error.message;
                }
            }
        },

        async promptSetTeamMemberExportLimit(teamMemberId: string, currentValue?: number) {
            const value = window.prompt('Set member\'s timesheet export limit (1-200)', currentValue ? currentValue.toString() : '');
            const numValue = value && parseInt(value);
            if (numValue && numValue > 0 && numValue <= 200) {
                try {
                    this.loading = true;
                    await updateParseObject(teamMemberId, 'Person', {timesheetsExportedLimit: numValue});
                    this.searchTeamMembers();
                } catch (e) {
                    window.alert(`Error occurred ${e.message}`);
                } finally {
                    this.loading = false;
                }
            } else {
                window.alert(`Value must be 1-200`);
            }
        }
    }
});
