










import Vue from 'vue';
import HeaderVue from '../components/Header.vue';
import LoginModalVue from '../components/LoginModal.vue';

export default Vue.extend({
    name: 'Layout',
    components: {HeaderVue, LoginModalVue},
});
