









import Vue from 'vue';
import {deleteSuppressedDestination} from '@/services/awsSesQueries';

export default Vue.extend({
    name: 'SesEmailLog',
    methods: {
        async deleteSuppression(email: string) {
            if (email && email.trim()) {
                await deleteSuppressedDestination(email);
            }
        }
    }
})
