








import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({});

Vue.filter('formatDate', function(value: any, format: string = 'DD-MMM-YYYY H:mm:ss'): string {
    if (typeof value === 'number') {
        return moment.unix(value).format(format);
    }
    if (value instanceof Date) {
        return moment(value).format(format);
    }
    return moment(value).format(format);
});
