import {getParseSingleTimeToken} from '../services/parseQueries';

export default async function logIntoTeam(userId: string, teamId: string): Promise<void> {
    const webAppUrl = process.env.VUE_APP_WEB_APP_URL;
    const singleTimeToken = await getParseSingleTimeToken(userId);
    window.open(
        `${webAppUrl}/#loginWithToken?token=${singleTimeToken}&teamId=${teamId}&admin=true`,
        '_blank'
    );
}
