







































































































import Vue from 'vue';
import BaseVue from './Base.vue';
import LoadingVue from '../components/Loading.vue';
import logIntoTeam from '../helpers/logIntoTeam';
import ModalVue from '../components/Modal.vue';
import moment from 'moment';
import 'moment-duration-format';
import Multiselect from 'vue-multiselect';
import {
    createApproval,
    deleteTeam,
    fetchLastJibbleDates,
    fetchSubscriptionPlans,
    fetchTeamInfo,
    fetchTeamMemberCounts,
    getCustomerPortal,
    reactivateSubscription,
    removePaymentMethod,
    resetApprovals,
    resetSubscription,
    saveTeamPermissions,
    updateParseObject,
    updateSubscription,
    switchToSubscriptionPlanType,
    updateSubscriptionAutoCollection,
    sendDebugNotification,
    initiateJibble2Migration,
    toggleTeamLock
} from '@/services/parseQueries';

const TEST_PAY_PERIOD_START_KEY = 'payPeriodStart';
const TEST_PAY_PERIOD_END_KEY = 'payPeriodEnd';

export default Vue.extend({
    name: 'EditTeam',
    extends: BaseVue,
    components: {LoadingVue, ModalVue, Multiselect},
    data() {
        return {
            errorMessage: '',
            loading: false,
            team: null as any,
            lastJibbleDate: '',
            teamMemberCount: 0,
            subscriptionInfo: null as any,
            subscriptionInfoStr: '',
            isTrialSubscription: false,
            isActiveSubscription: false,
            trialStartedAt: '',
            trialEndsAt: '',
            trialEndsIn: '',
            currentTermStartedAt: '',
            currentTermEndsAt: '',
            currentTermEndsIn: '',
            subscriptionAdminConsoleUrl: '',
            customerAdminConsoleUrl: '',
            showSubscriptionDetails: false,
            showModal: false,
            modalHeader: '',
            modalBodyHtml: '',
            placeholderText: '',
            modalShowInput: false,
            modalShowMultiselect: false,
            modalInputValue: '',
            modalInputError: '',
            modalInputInvalid: false,
            modalConfirmAction: () => {},
            modalLoading: false,
            actionResult: '',
            actionError: false,
            showActionResult: false,
            actionAfterResult: () => {},
            freePowerUpsList: [
                'activity', 'billing', 'collaborators', 'passcode',
                'policies', 'location', 'facerecognition',
                'slack', 'msteams', 'workschedule', 'productivity',
                'payrollpandaintegration', 'approvals'
            ],
            teamPermissionsFreePowerUps: []
        };
    },

    computed: {
        permissionsMaxMembers: function() {
            return this.team.permissions && typeof this.team.permissions.maxMembers === 'number' ?
                this.team.permissions.maxMembers : 'DEFAULT';
        },
        permissionsFreePowerUps: function() {
            return this.team.permissions && Array.isArray(this.team.permissions.freePowerUps) ?
                this.team.permissions.freePowerUps : '[]';
        }
    },

    async mounted() {
        this.loading = true;
        this.loadTeamInfo();
    },

    methods: {
        async loadTeamInfo() {
            try {
                const teamId = this.$route.params.id;
                const [team, subscriptionInfo] = await fetchTeamInfo(teamId);
                const [lastJibbleDate] = await fetchLastJibbleDates([teamId]);
                const [teamMemberCount] = await fetchTeamMemberCounts([teamId]);

                this.team = team;
                this.subscriptionInfo = subscriptionInfo;
                const lastJibbleMoment = moment(lastJibbleDate);
                this.lastJibbleDate = lastJibbleMoment.isValid() ? lastJibbleMoment.format('DD-MMM-YYYY H:mm:ss') : 'n/a';
                this.teamMemberCount = teamMemberCount;

                this.subscriptionInfoStr = JSON.stringify(subscriptionInfo, null, 2);
                this.isTrialSubscription = subscriptionInfo.subscription.status === 'in_trial';
                this.isActiveSubscription = subscriptionInfo.subscription.status === 'active';
                this.trialStartedAt = moment.unix(subscriptionInfo.subscription.trial_start).format();
                this.trialEndsAt = moment.unix(subscriptionInfo.subscription.trial_end).format();

                if (this.isTrialSubscription) {
                    const trialEndsInSeconds = moment(this.trialEndsAt).diff(moment(), 'seconds', true);
                    this.trialEndsIn = moment
                        .duration(trialEndsInSeconds, 'seconds')
                        .format('d [days] h [hrs] mm [mins] ss [secs]');
                }
                this.currentTermStartedAt = moment.unix(subscriptionInfo.subscription.current_term_start).format();
                this.currentTermEndsAt = moment.unix(subscriptionInfo.subscription.current_term_end).format();

                if (this.isActiveSubscription) {
                    const currentTermEndsInSeconds = moment(this.currentTermEndsAt).diff(moment(), 'seconds', true);
                    this.currentTermEndsIn = moment
                        .duration(currentTermEndsInSeconds, 'seconds')
                        .format('d [days] h [hrs] mm [mins] ss [secs]');
                }
                const site = subscriptionInfo.site;
                const subscriptionId = subscriptionInfo.subscription.id;
                this.subscriptionAdminConsoleUrl = `https://${site}.chargebee.com/admin-console/subscriptions/${subscriptionId}`;
                const customerId = subscriptionInfo.subscription.customer_id;
                this.customerAdminConsoleUrl = `https://${site}.chargebee.com/admin-console/customers/${customerId}`;
            } catch (error) {
                this.errorMessage = error.response.data.error.message;
                this.team = null;
                this.subscriptionInfo = null;
            } finally {
                this.loading = false;
            }
        },

        async parseLogin(userId: string, teamId: string) {
            try {
                await logIntoTeam(userId, teamId);
            } catch (error) {
                this.errorMessage = error.message;
            }
        },

        promptSetTeamPermissionNum(key: string, label: string, currentValue: number | string) {
            this.modalHeader = `Confirm ${label} update`;
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = typeof currentValue === 'number' ? currentValue.toString(10) : '';
            this.placeholderText = 'Enter value';
            this.modalConfirmAction = async () => {
                const value = parseInt(this.modalInputValue);
                const attributes = {
                    teamId: this.team.objectId,
                    attributes: {
                        [key]: typeof value === 'number' ? value : null
                    }
                };
                await this.saveTeamPermissions(attributes);
            };
            this.actionError = false;
            this.showModal = true;
        },

        promptSetTeamBool(key: string, label: string, value: boolean) {
            this.modalHeader = `Confirm ${label} update`;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            this.modalConfirmAction = async () => {
                const attributes = {
                    [key]: value
                };
                try {
                    this.modalLoading = true;
                    await updateParseObject(this.team.objectId, 'Company', attributes);
                    await this.loadTeamInfo();
                } catch (error) {
                    this.actionResult = error.response.data.error.message;
                    this.actionError = true;
                    this.showActionResult = true;
                } finally {
                    this.modalLoading = false;
                    this.showModal = false;
                    this.modalInputValue = '';
                }
            };
            this.actionError = false;
            this.showModal = true;
        },

        promptSetTeamPermissionMultiselect() {
            this.modalHeader = 'Confirm free power-ups update';
            this.modalBodyHtml = '';
            this.modalShowInput = false;
            this.modalShowMultiselect = true;
            this.teamPermissionsFreePowerUps = (this.team.permissions && this.team.permissions.freePowerUps) || [];
            this.modalConfirmAction = async () => {
                const attributes = {
                    teamId: this.team.objectId,
                    attributes: {
                        freePowerUps: this.teamPermissionsFreePowerUps
                    }
                };
                await this.saveTeamPermissions(attributes);
            };
            this.actionError = false;
            this.showModal = true;
        },

        promptDeleteTeam() {
            this.modalHeader = 'Are you sure you want to permanently delete a team?';
            this.modalBodyHtml = 'Team will be deleted in 24 hours<br><b>Note:</b> <span style="color: red;"><b>deletion cannot be reverted. You have been warned</b></span>';
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Type in "I Understand"';
            this.modalInputInvalid = false;
            this.placeholderText = 'Type in "I Understand"';
            this.modalConfirmAction = this.deleteTeam;
            this.actionError = false;
            this.showModal = true;
        },

        promptReactivateSubscription() {
            this.modalHeader = 'Reactivate subscription';
            this.modalBodyHtml = 'If time is provided then subscription will be moved to trial state ' +
                '(assuming it has not been activated yet). ' +
                'If time is not provided then subscription will be moved to active state immediately ' +
                '(valid payment method must be available).';
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid time';
            this.modalInputInvalid = false;
            this.placeholderText = '[hh][:mm][:ss]';
            this.modalConfirmAction = this.reactivateSubscription;
            this.actionError = false;
            this.showModal = true;
        },

        promptChangeTermEnd() {
            this.modalHeader = 'Enter amount of time from now when current term must end';
            this.modalBodyHtml = `<ul><li>If the Subscription is in trial, it affects trial end date.</li>
                <li>If the Subscription is active, it affects the next billing date.</li>
                <li>If the Subscription's status is non_renewing, this affects the upcoming cancellation date.</li></ul>`;
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid time';
            this.modalInputInvalid = false;
            this.placeholderText = 'hhhh[:mm][:ss]';
            this.modalConfirmAction = this.changeTermEnd;
            this.actionError = false;
            this.showModal = true;
        },

        promptSetTeamLockedOut() {
            this.modalHeader = 'Are you sure you want to toggle lockout for this team?';
            this.modalBodyHtml = '';
            this.modalShowInput = !this.team.lockedOut;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputInvalid = false;
            this.placeholderText = 'Lockout reason (optional)';
            this.modalConfirmAction = async () => {
                const lockedOut = this.team.lockedOut;
                try {
                    this.modalLoading = true;
                    await toggleTeamLock(this.team.objectId, !lockedOut, this.modalInputValue || undefined)
                    await this.loadTeamInfo();
                } catch (error) {
                    this.actionResult = error.response.data.error.message;
                    this.actionError = true;
                    this.showActionResult = true;
                } finally {
                    this.modalLoading = false;
                    this.showModal = false;
                    this.modalInputValue = '';
                }
            };
            this.actionError = false;
            this.showModal = true;
        },

        promptCreatePayPeriod() {
            const payPeriodStart = localStorage.getItem(TEST_PAY_PERIOD_START_KEY) ||
                moment().startOf('month').format('YYYY-MM-DD');
            const payPeriodEnd = localStorage.getItem(TEST_PAY_PERIOD_END_KEY) ||
                moment().endOf('month').format('YYYY-MM-DD');
            this.modalHeader = 'Create pay period timesheets';
            this.modalBodyHtml = `
                Pay period start: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodStart" value="${payPeriodStart}" required><br />
                Pay period end: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodEnd" value="${payPeriodEnd}" required>
            `;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = '';
            this.modalInputInvalid = false;
            this.placeholderText = '';
            this.modalConfirmAction = this.createApproval;
            this.actionError = false;
            this.showModal = true;
        },

        promptSendAutoSubmittedNotification() {
            const payPeriodStart = localStorage.getItem(TEST_PAY_PERIOD_START_KEY) ||
                moment().startOf('month').format('YYYY-MM-DD');
            const payPeriodEnd = localStorage.getItem(TEST_PAY_PERIOD_END_KEY) ||
                moment().endOf('month').format('YYYY-MM-DD');
            this.modalHeader = 'Send timesheets auto-submission notification';
            this.modalBodyHtml = `
                Pay period start: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodStart" value="${payPeriodStart}" required><br />
                Pay period end: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodEnd" value="${payPeriodEnd}" required>
            `;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = '';
            this.modalInputInvalid = false;
            this.placeholderText = '';
            this.modalConfirmAction = this.sendAutoSubmittedNotification;
            this.actionError = false;
            this.showModal = true;
        },

        promptSendReviewTimesheetReminder() {
            const payPeriodStart = localStorage.getItem(TEST_PAY_PERIOD_START_KEY) ||
                moment().startOf('month').format('YYYY-MM-DD');
            const payPeriodEnd = localStorage.getItem(TEST_PAY_PERIOD_END_KEY) ||
                moment().endOf('month').format('YYYY-MM-DD');
            this.modalHeader = 'Send review timesheet reminder';
            this.modalBodyHtml = `
                Pay period start: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodStart" value="${payPeriodStart}" required><br />
                Pay period end: <input type="text" placeholder="YYYY-MM-DD" id="payPeriodEnd" value="${payPeriodEnd}" required>
            `;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = '';
            this.modalInputInvalid = false;
            this.placeholderText = '';
            this.modalConfirmAction = this.sendReviewTimesheetReminder;
            this.actionError = false;
            this.showModal = true;
        },

        promptRemovePaymentMethod() {
            this.modalHeader = 'Are you sure you want to delete default payment method?';
            this.modalBodyHtml = '<b>Note:</b> <span style="color: red;"><b>this cannot be reverted</b></span>';
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputError = 'Invalid owner email';
            this.modalInputInvalid = false;
            this.placeholderText = 'Type in owner email';
            this.modalConfirmAction = this.removePaymentMethod;
            this.actionError = false;
            this.showModal = true;
        },

        promptResetSubscription() {
            this.modalHeader = 'Are you sure you want to reset a subscription for a team?';
            this.modalBodyHtml = `<b>Note:</b> all current subscription data like transactions and invoices will ` +
                `be unlinked from the team and preserved.`;
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid team name';
            this.modalInputInvalid = false;
            this.placeholderText = 'Type in team name';
            this.modalConfirmAction = this.resetSubscription;
            this.actionError = false;
            this.showModal = true;
        },

        promptResetApprovals() {
            this.modalHeader = 'Are you sure you want to reset the approvals feature for a team?';
            this.modalBodyHtml = `<b>Note:</b> This also deletes all related data like approval timesheets and their action logs and
                <span style="color: red;"><b>cannot be reverted</b></span>.`;
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid team name';
            this.modalInputInvalid = false;
            this.placeholderText = 'Type in team name';
            this.modalConfirmAction = this.resetApprovals;
            this.actionError = false;
            this.showModal = true;
        },

        promptStartJibble2Migration() {
            this.modalHeader = 'Are you sure you want to start migration to Jibble 2 for a team?';
            this.modalShowInput = true;
            this.modalShowMultiselect = false;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid team name';
            this.modalInputInvalid = false;
            this.placeholderText = 'Type in team name';
            this.modalConfirmAction = this.startJibble2Migration;
            this.actionError = false;
            this.showModal = true;
        },

        async startJibble2Migration() {
            this.modalInputInvalid = false;
            if (this.modalInputValue !== this.team.name) {
                this.modalInputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await initiateJibble2Migration(this.team.objectId);
                this.actionResult = 'Success';
                this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        cancelModal() {
            this.showModal = false;
            this.modalBodyHtml = '';
        },

        closeResult() {
            this.showActionResult = false;
            if (this.actionAfterResult) {
                this.actionAfterResult();
            }
        },

        async saveTeamPermissions(attributes: object) {
            try {
                this.modalLoading = true;
                await saveTeamPermissions(attributes);
                await this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
                this.showActionResult = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
            }
        },

        async deleteTeam() {
            this.modalInputInvalid = false;
            if (this.modalInputValue !== 'I Understand') {
                this.modalInputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await deleteTeam(this.team.objectId);
                this.actionResult = 'Team is scheduled for deletion';
                this.actionAfterResult = () => this.$router.push('/teams');
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        toggleSubscriptionDetails() {
            this.showSubscriptionDetails = !this.showSubscriptionDetails;
        },

        async promptSwitchToPlanType() {
            const subscriptionPlans = await fetchSubscriptionPlans();
            this.modalHeader = `Switch to plan type`;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            const currencyCode: string = this.subscriptionInfo.subscription.currency_code;
            const options = subscriptionPlans
                .filter((sp: {type: string}) => sp.type.startsWith('v3_'))
                .filter((sp: {currencyCode: string}) => sp.currencyCode === currencyCode)
                .map((sp: {type: string, name: string, billingPeriodUnit: string}) => {
                    return `<option value="${sp.type}">${sp.name} (${sp.type}) [${sp.billingPeriodUnit}]</option>`;
                }).join('');
            this.modalBodyHtml = `
                <select id="planTypeSelect">
                <option disabled selected>Select plan type</option>
                ${options}
                </select>
                <br>
                <input id="userAmountInput" type="number" min="1" placeholder="User amount">
            `;
            this.modalConfirmAction = async () => {
                const planType = (document.getElementById('planTypeSelect') as HTMLSelectElement).value;
                const userAmount = (document.getElementById('userAmountInput') as HTMLInputElement).value;
                const userAmountNum = parseInt(userAmount, 10) || 0;
                try {
                    this.modalLoading = true;
                    await switchToSubscriptionPlanType(this.team.objectId, planType, userAmountNum);
                    await this.loadTeamInfo();
                } catch (error) {
                    this.actionResult = error.response.data.error.message;
                    this.actionError = true;
                    this.showActionResult = true;
                } finally {
                    this.modalLoading = false;
                    this.showModal = false;
                    this.modalInputValue = '';
                }
            };
            this.actionError = false;
            this.showModal = true;
        },

        async toggleAutoCollection() {
            this.modalHeader = `Confirm auto collection update`;
            this.modalShowInput = false;
            this.modalShowMultiselect = false;
            this.modalConfirmAction = async () => {
                try {
                    this.modalLoading = true;
                    const currentAutoCollection =
                        this.subscriptionInfo.subscription.auto_collection || this.subscriptionInfo.customer.auto_collection;
                    const autoCollection = currentAutoCollection === 'on' ? 'off' : 'on';
                    await updateSubscriptionAutoCollection(this.team.objectId, autoCollection);
                    await this.loadTeamInfo();
                } catch (error) {
                    this.actionResult = error.response.data.error.message;
                    this.actionError = true;
                    this.showActionResult = true;
                } finally {
                    this.modalLoading = false;
                    this.showModal = false;
                    this.modalInputValue = '';
                }
            };
            this.actionError = false;
            this.showModal = true;
        },

        async openCustomerPortal() {
            const win = window.open('about:blank', '_blank');
            if (!win) {
                return;
            }

            try {
                win.document.write('Loading customer portal...');
                const result = await getCustomerPortal(this.team.objectId);
                win.location.href = result.accessUrl;
            } catch (error) {
                this.errorMessage = error.response.data.error.message;
                win.document.write(error.response.data.error.message);
            }
        },

        async reactivateSubscription() {
            const exec = /^(\d{1,2})(:(\d{1,2}))?(:(\d{1,2}))?$/.exec(this.modalInputValue);
            let trialEndsAt;

            if (this.modalInputValue) {
                if (!exec) {
                    this.modalInputInvalid = true;
                    return;
                }

                const hours = Number(exec[1]);
                const minutes = Number(exec[3] || 0);
                const seconds = Number(exec[5] || 0);
                trialEndsAt = moment()
                    .add(hours, 'hours')
                    .add(minutes, 'minutes')
                    .add(seconds, 'seconds');
            }

            try {
                this.modalLoading = true;
                await reactivateSubscription(this.team.objectId, trialEndsAt);
                this.actionResult = 'Successfully reactivated subscription';
                this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalBodyHtml = '';
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async changeTermEnd() {
            const exec = /^(\d{1,4})(:(\d{1,2}))?(:(\d{1,2}))?$/.exec(this.modalInputValue);
            if (!exec) {
                this.modalInputInvalid = true;
                return;
            }

            const hours = Number(exec[1]);
            const minutes = Number(exec[3] || 0);
            const seconds = Number(exec[5] || 0);
            const termEndsAt = moment()
                .add(hours, 'hours')
                .add(minutes, 'minutes')
                .add(seconds, 'seconds');

            try {
                this.modalLoading = true;
                await updateSubscription(this.team.objectId, termEndsAt);
                this.actionResult = 'Successfully updated subscription';
                this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalBodyHtml = '';
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async createApproval() {
            const payPeriodStartEl: any = document.getElementById('payPeriodStart');
            const payPeriodEndEl: any = document.getElementById('payPeriodEnd');
            if (!payPeriodStartEl || !payPeriodStartEl.value || !payPeriodEndEl || !payPeriodEndEl.value) {
                return;
            }
            const teamId = this.team.objectId;
            const payPeriodStart = payPeriodStartEl.value;
            const payPeriodEnd = payPeriodEndEl.value;
            localStorage.setItem(TEST_PAY_PERIOD_START_KEY, payPeriodStart);
            localStorage.setItem(TEST_PAY_PERIOD_END_KEY, payPeriodEnd);
            try {
                this.modalLoading = true;
                await createApproval(teamId, payPeriodStart, payPeriodEnd);
                this.actionResult = 'Successfully created approval timesheets';
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalBodyHtml = '';
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async sendReviewTimesheetReminder() {
            const payPeriodStartEl: any = document.getElementById('payPeriodStart');
            const payPeriodEndEl: any = document.getElementById('payPeriodEnd');
            if (!payPeriodStartEl || !payPeriodStartEl.value || !payPeriodEndEl || !payPeriodEndEl.value) {
                return;
            }
            const teamId = this.team.objectId;
            const payPeriodStart = payPeriodStartEl.value;
            const payPeriodEnd = payPeriodEndEl.value;
            localStorage.setItem(TEST_PAY_PERIOD_START_KEY, payPeriodStart);
            localStorage.setItem(TEST_PAY_PERIOD_END_KEY, payPeriodEnd);
            try {
                await sendDebugNotification({teamId, payPeriodStart, payPeriodEnd, notificationType: 'reviewApprovalTimesheets'});
                this.actionResult = 'Successfully sent review timesheet reminder';
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async sendAutoSubmittedNotification() {
            const payPeriodStartEl: any = document.getElementById('payPeriodStart');
            const payPeriodEndEl: any = document.getElementById('payPeriodEnd');
            if (!payPeriodStartEl || !payPeriodStartEl.value || !payPeriodEndEl || !payPeriodEndEl.value) {
                return;
            }
            const teamId = this.team.objectId;
            const payPeriodStart = payPeriodStartEl.value;
            const payPeriodEnd = payPeriodEndEl.value;
            try {
                await sendDebugNotification({teamId, payPeriodStart, payPeriodEnd, notificationType: 'timesheetsAutoSubmitted'});
                this.actionResult = 'Successfully sent timesheets auto-submitted notification';
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async removePaymentMethod() {
            this.modalInputInvalid = false;
            if (this.modalInputValue !== this.team.owner.email) {
                this.modalInputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await removePaymentMethod(this.team.owner.objectId);
                this.actionResult = 'Successfully removed payment method';
                this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async resetSubscription() {
            this.modalInputInvalid = false;
            if (this.modalInputValue !== this.team.name) {
                this.modalInputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await resetSubscription(this.team.objectId);
                this.actionResult = 'Successfully reset a subscription';
                this.loadTeamInfo();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        },

        async resetApprovals() {
            this.modalInputInvalid = false;
            if (this.modalInputValue !== this.team.name) {
                this.modalInputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await resetApprovals(this.team.objectId);
                this.actionResult = 'Successfully reset approvals power-up';
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.modalInputValue = '';
                this.showActionResult = true;
            }
        }
    }
});
