





















import Vue from 'vue';
import {mapState} from 'vuex';
import {LOGIN_REQUEST, USER_EMAIL} from '../store/types';

export default Vue.extend({
    name: 'Login',
    data() {
        return {
            email: localStorage.getItem(USER_EMAIL),
            password: '',
            totpCode: '',
            errorMessage: ''
        };
    },
    computed: mapState(
        ['require2fa']
    ),
    methods: {
        async login() {
            this.errorMessage = '';
            try {
                const {email, password, totpCode} = this;
                await this.$store.dispatch(LOGIN_REQUEST, {email, password, totpCode});
                this.$router.push('/');
            } catch (error) {
                this.errorMessage = error.response ? error.response.data : error.message;
            }
        },
    }
});
