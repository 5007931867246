






















import Vue from 'vue'
import {mapState} from 'vuex';
import LoadingVue from '../components/Loading.vue';
import ModalVue from '../components/Modal.vue';
import {LOGIN_REQUEST, LOGOUT_REQUEST, USER_EMAIL} from '../store/types';

export default Vue.extend({
    name: 'LoginModal',
    components: {LoadingVue, ModalVue},
    computed: mapState(['require2fa', 'tokenExpired']),
    data() {
        return {
            email: localStorage.getItem(USER_EMAIL),
            password: '',
            totpCode: '',
            errorMessage: '',
            modalLoading: false
        }
    },
    methods: {
        async login() {
            this.errorMessage = '';
            try {
                const {email, password, totpCode} = this;
                this.modalLoading = true;
                await this.$store.dispatch(LOGIN_REQUEST, {email, password, totpCode});
                this.$router.go(0);
            } catch (error) {
                this.errorMessage = error.response ? error.response.data : error.message;
            } finally {
                this.modalLoading = false;
                this.email = '';
                this.password = '';
                this.totpCode = '';
            }
        },

        async cancelLogin() {
            await this.$store.dispatch(LOGOUT_REQUEST);
            this.$router.push('/login');
        }
    }
})
