import axios from '../http';
import {AxiosRequestConfig} from 'axios';
import {LOGIN, LOGOUT, VALIDATE_TOKEN} from '../../../paths';
import {Credentials} from 'types';

async function login(credentials: Credentials) {
    const requestConfig: AxiosRequestConfig = {
        url: LOGIN,
        method: 'POST',
        data: credentials
    };

    const response = await axios.request(requestConfig);
    return response.data;
}

function refreshToken() {
    const requestConfig: AxiosRequestConfig = {
        url: `api/AdminAccessTokens/${VALIDATE_TOKEN}`,
        method: 'GET'
    };
    return axios.request(requestConfig);
}

async function logout() {
    const requestConfig: AxiosRequestConfig = {
        url: LOGOUT,
        method: 'POST'
    };

    await axios.request(requestConfig);
}

export {login, logout, refreshToken};
