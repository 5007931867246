import Vue from 'vue';
import Router, {Route} from 'vue-router';
import store from '../src/store/store';
import {ADMIN, AUTHENTICATED, VIEWER} from '../../types';

Vue.use(Router);

function auth(to: Route, from: Route, next: Function) {
    if (to.meta.public) {
        return next();
    }

    if (store.getters.isLoggedIn) {
        if (isAuthorized(to.meta.allowed)) {
            return next();
        } else {
            return next(from);
        }
    }
    next('/login');
}

function isAuthorized(roles: string[]) {
    const userRoles = store.getters.roles;
    for (let i = 0; i < roles.length; i++) {
        if (roles[i] === AUTHENTICATED || userRoles.includes(roles[i])) {
            return true;
        }
    }
}

const router = new Router({
    routes: [
        {
            path: '/login',
            meta: {public: true},
            component: () => import('./views/Login.vue')
        },
        {
            path: '/',
            redirect: 'dashboard',
            component: () => import('./views/Layout.vue'),
            children: [
                {
                    path: 'dashboard',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/Dashboard.vue')
                },
                {
                    path: 'teams',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/Teams.vue')
                },
                {
                    path: 'teams/:id/edit',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/EditTeam.vue')
                },
                {
                    path: 'teams/:id/powerUps',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/TeamPowerUps.vue')
                },
                {
                    path: 'teamMembers',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/TeamMembers.vue')
                },
                {
                    path: 'users',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/Users.vue')
                },
                {
                    path: 'emails',
                    meta: {allowed: [ADMIN, VIEWER]},
                    component: () => import('./views/Emails.vue')
                },
                {
                    path: 'marketingExport',
                    meta: {allowed: [ADMIN]},
                    component: () => import('./views/MarketingExport.vue')
                }
            ]
        },
        {
            path: '*',
            meta: {allowed: [AUTHENTICATED]},
            component: () => import('./views/Error404.vue')
        }
    ]
});

router.beforeEach(auth);

export default router;
