import axios from 'axios';
import {PARSE} from '../../paths';
import store from './store/store';
import {TOKEN_EXPIRED} from './store/types';

const config = {
    baseURL: process.env.VUE_APP_API_SERVER_URL || undefined,
    withCredentials: true
};
const Axios = axios.create(config);

Axios.interceptors.response.use(
    response => response,
    async error => {
        if (error.config.url.indexOf(PARSE) !== -1 && error.response.status === 401) {
            store.dispatch(TOKEN_EXPIRED);
        }
        throw error;
    }
);

export default Axios;
