











import Vue from 'vue'
import {LOGOUT_REQUEST} from '../store/types';
import {ADMIN} from '../../../types';

export default Vue.extend({
    name: 'Navigation',
    data() {
        return {
            ADMIN
        }
    },
    computed: {
        roles(): string[] {
            return this.$store.getters.roles;
        }
    },
    methods: {
        userHasRole(role: string) {
            return this.roles.includes(role);
        },
        async logout() {
            this.$store.dispatch(LOGOUT_REQUEST);
            this.$router.push('/login');
        }
    }
})
