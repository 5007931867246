



































































import Vue from 'vue';
import LoadingVue from '../components/Loading.vue';
import logIntoTeam from '../helpers/logIntoTeam';
import moment from 'moment';
import {fetchLastJibbleDates, fetchTeamMemberCounts, fetchTeamsAndTeamCount} from '../services/parseQueries';

enum SearchMethod {
    SearchTerm = 'searchTerm',
    MSTenantId = 'msTenantId'
}

export default Vue.extend({
    name: 'Teams',
    components: {LoadingVue},
    data() {
        return {
            searchTerm: '' as string,
            msTenantId: '' as string,
            searchMethod: SearchMethod.SearchTerm as SearchMethod,
            loading: false,
            errorMessage: '',
            teams: [],
            totalTeams: 0,
            teamMemberCounts: [],
            lastJibbleDates: [],
            page: 0,
            limit: 15,
            sortKey: 'name',
            sortOrder: 'asc'
        };
    },
    computed: {
        isLastPage(): boolean {
            return (this.page + 1) * this.limit >= this.totalTeams;
        },
        isFirstPage(): boolean {
            return this.page === 0;
        },
        teamCountStart(): number {
            return this.page * this.limit + 1;
        },
        teamCountEnd(): number {
            return Math.min(this.totalTeams, (this.page + 1) * this.limit);
        }
    },
    async mounted() {
        if (this.$route.query.q) {
            this.searchTerm = this.$route.query.q.toString();
        } else if (this.$route.query.msTenantId) {
            this.msTenantId = this.$route.query.msTenantId.toString();
        }
        this.loading = true;
        this.loadTeams();
    },
    methods: {
        load(searchMethod: SearchMethod) {
            this.searchMethod = searchMethod;
            if (searchMethod === SearchMethod.SearchTerm) {
                this.$router.replace({path: 'teams', query: {q: this.searchTerm}});
            } else if (searchMethod === SearchMethod.MSTenantId) {
                this.$router.replace({path: 'teams', query: {msTenantId: this.msTenantId}});
            }

            this.loadTeams(true);
        },

        async loadTeams(resetPage = false) {
            this.errorMessage = '';

            if (resetPage) {
                this.page = 0;
            }

            const options = {
                page: this.page,
                limit: this.limit,
                searchTerm: this.searchMethod === SearchMethod.SearchTerm ? this.searchTerm : undefined,
                msTenantId: this.searchMethod === SearchMethod.MSTenantId ? this.msTenantId : undefined,
                sortKey: this.sortKey,
                sortOrder: this.sortOrder
            };

            try {
                [this.teams, this.totalTeams] = await fetchTeamsAndTeamCount(options);
                this.teams.filter((team: any) => team.owner);
                this.loading = false;

                let teamIds: string[] = this.teams.map((team: any) => {
                    return team.objectId;
                });

                this.teamMemberCounts = await fetchTeamMemberCounts(teamIds, true);
                this.lastJibbleDates = await fetchLastJibbleDates(teamIds);
            } catch (error) {
                this.loading = false;
                this.errorMessage = error.response ? error.response.data.error.message : error.message;
                this.teams = [];
                this.totalTeams = 0;
            }
        },

        async parseLogin(userId: string, teamId: string) {
            try {
                await logIntoTeam(userId, teamId);
            } catch (error) {
                this.errorMessage = error.message;
            }
        },

        formatDate(date: Date, format: string) {
            if (date) {
                return moment(date).format(format);
            } else {
                return '-';
            }
        },

        goToPage(pageNumber: number) {
            this.page = pageNumber;
            this.loadTeams();
        },

        getIconName(sortKey: string) {
            if (sortKey !== this.sortKey) {
                return 'sort';
            }

            return this.sortKey === 'asc' ? 'sort-down' : 'sort-up';
        },

        sortTeams(key: string) {
            if (this.sortKey === key) {
                this.sortOrder = this.sortOrder === 'asc' ? 'dsc' : 'asc';
            } else {
                this.sortKey = key;
                this.sortOrder = 'asc';
            }
            this.loadTeams();
        },

        teamMemberCount(index: number): number {
            return this.teamMemberCounts[index];
        },

        lastJibbleDate(index: number): Date {
            return this.lastJibbleDates[index];
        }
    }
});
