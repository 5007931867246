
import Vue from 'vue';
import {ADMIN} from '../../../types';

export default Vue.extend({
    name: 'Base',
    data() {
        return {
            ADMIN
        }
    },
    computed: {
        roles(): string[] {
            return this.$store.getters.roles;
        }
    },
    methods: {
        userHasRole(role: string): boolean {
            return this.roles.includes(role);
        }
    }
});
