import axios from '../http';
import {AGENDA, JOBS, POST_JOB} from '../../../paths';
import {RETURN_ARG_NAME} from '../../../types';

export async function searchJobs(query: string) {
    const result = await axios.get(`${AGENDA}/${JOBS}`, {params: {query}});
    return result.data[RETURN_ARG_NAME];
}

export async function scheduleJob(attributes: {name: string, nextRunAt: Date, data?: object}) {
    const result = await axios.post(`${AGENDA}/${POST_JOB}`, {attributes});
    return result.data[RETURN_ARG_NAME];
}
