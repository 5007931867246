export const AGENDA = 'api/agenda';
export const CLOUD_FUNCTION = 'cloudFunction';
export const DELETE_TEAM_MEMBER = 'deleteTeamMember';
export const FETCH_ADMIN_STATS = 'fetchAdminStats';
export const JOBS = 'jobs';
export const LAST_JIBBLE_DATES = 'lastJibbleDates';
export const PARSE = 'api/parse';
export const POST_JOB = 'postJob';
export const POWER_UPS_FOR_TEAM = 'powerUpsForTeam';
export const FREE_POWER_UPS_FOR_TEAM = 'freePowerUpsForTeam';
export const RECREATE_POWER_UP_FOR_TEAM = 'recreatePowerUpForTeam';
export const POWER_UP_TOGGLE = 'powerUpToggle';
export const UPDATE_PARSE_OBJECT = 'updateParseObject';
export const SAVE_TEAM_PERMISSIONS = 'saveTeamPermissions';
export const SINGLE_TIME_TOKEN = 'singleTimeToken';
export const SUBSCRIPTION_PLANS = 'subscriptionPlans';
export const TEAM_INFO = 'team';
export const TEAM_MEMBER_COUNTS = 'teamMemberCounts';
export const TEAM_MEMBERS = 'teamMembers';
export const TEAM_MEMBERS_FOR_USER = 'teamMembersForUser';
export const TEAMS = 'teams';
export const USERS = 'users';
export const EMAIL_BOUNCES = 'bounces';
export const DELETE_AWS_SES_SUPPRESSED_DESTINATION = 'deleteAWSSESSuppressedDestination';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const VALIDATE_TOKEN = 'validateToken';
export const SLACK_API_URL = 'https://slack.com/api/';
