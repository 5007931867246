


























































import Vue from 'vue';
import {scheduleJob, searchJobs} from '@/services/agendaQueries';
import LoadingVue from '@/components/Loading.vue';
import ModalVue from '@/components/Modal.vue';
import moment from 'moment';
// @ts-ignore
import Pikaday from 'pikaday';

const NUMBER_OF_REPORTS = 10;

export default Vue.extend({
    name: 'MarketingExport',
    components: {ModalVue, LoadingVue},
    data() {
        return {
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            searchQuery: 'send marketing report',
            searching: false,
            searchErrorMessage: '',
            validationErrorMessage: '',
            schedulingJob: false,
            scheduleError: false,
            result: '',
            jobs: [],
            moment: moment,
            showModal: false,
            showResult: false,
            jobWhen: '',
            jobData: '{}'
        }
    },
    async mounted() {
        this.searchJob();
    },
    methods: {
        closeModal() {
            this.showResult = false;
            this.searchJob();
        },
        openModal() {
            this.showModal = true;
            setTimeout(() => {
                new Pikaday({ field: document.getElementById('startDate'), onSelect: (date:string) => this.startDate = moment(date).format('YYYY-MM-DD') });
                new Pikaday({ field: document.getElementById('endDate'), onSelect: (date:string) => this.endDate = moment(date).format('YYYY-MM-DD') });
            }, 0);
        },
        async searchJob() {
            this.searchErrorMessage = '';
            this.searching = true;
            try {
                this.jobs = await searchJobs(`{"name": "${this.searchQuery}"}`);
                this.jobs.sort((a:any, b:any) => ((a.nextRunAt || a.lastRunAt) > (b.nextRunAt || b.lastRunAt)) ? -1 : 1)
                this.jobs = this.jobs.slice(0, NUMBER_OF_REPORTS)
            } catch (error) {
                this.searchErrorMessage = error.response ? error.response.data.error.message : error.message;
            } finally {
                this.searching = false;
            }
        },

        async scheduleJob() {
            this.validationErrorMessage = '';
            const nextRunAt = moment();

            let data;
            try {
                data = {
                    startDate: this.startDate,
                    endDate: this.endDate
                };
            } catch (error) {
                this.validationErrorMessage = error.message;
                return;
            }

            try {
                this.schedulingJob = true;
                this.result = '';
                this.scheduleError = false;
                const attributes = {
                    name: 'send marketing report',
                    nextRunAt: nextRunAt.toDate(),
                    data
                };
                this.result = await scheduleJob(attributes);
                this.searchJob();
            } catch (error) {
                this.result = error.response ? error.response.data.error.message : error.message;
                this.scheduleError = true;
            } finally {
                this.schedulingJob = false;
                this.showModal = false;
                this.showResult = true;
            }

        }
    }
});
