import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import {REFRESH_TOKEN_REQUEST} from './store/types';
import './assets/main.scss';
import 'moment-duration-format';

Vue.config.productionTip = false;

store.dispatch(REFRESH_TOKEN_REQUEST).then(() => {
    const vue = new Vue({
        router,
        store,
        render: h => h(App)
    });
    vue.$mount('#app');

    if (google) {
        google.charts.load('current', {packages: ['corechart']});
        google.charts.setOnLoadCallback(() => {
            vue.$emit('google:chartsLoaded');
        });
    }
});
