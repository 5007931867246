import axios from '../http';
import {
    CLOUD_FUNCTION,
    DELETE_TEAM_MEMBER,
    FETCH_ADMIN_STATS,
    LAST_JIBBLE_DATES,
    PARSE,
    RECREATE_POWER_UP_FOR_TEAM,
    POWER_UP_TOGGLE,
    POWER_UPS_FOR_TEAM,
    FREE_POWER_UPS_FOR_TEAM,
    UPDATE_PARSE_OBJECT,
    SAVE_TEAM_PERMISSIONS,
    SINGLE_TIME_TOKEN,
    SLACK_API_URL,
    SUBSCRIPTION_PLANS,
    TEAMS,
    TEAM_INFO,
    TEAM_MEMBER_COUNTS,
    TEAM_MEMBERS,
    TEAM_MEMBERS_FOR_USER,
    USERS,
    EMAIL_BOUNCES
} from '../../../paths';
import {RETURN_ARG_NAME} from '../../../types';
import {Moment} from 'moment';
import {AxiosRequestConfig} from 'axios';

async function defaultGetQuery(endpoint: string, params?: object) {
    const result = await axios.get(`${PARSE}/${endpoint}`, {params});
    return result.data[RETURN_ARG_NAME];
}

async function defaultPostQuery(endpoint: string, data: object) {
    const result = await axios.post(`${PARSE}/${endpoint}`, data);
    return result.data[RETURN_ARG_NAME];
}

async function defaultDeleteQuery(endpoint: string) {
    const result = await axios.delete(`${PARSE}/${endpoint}`);
    return result.data[RETURN_ARG_NAME];
}

export async function cloudFunction(name: string, params: object) {
    const data = {
        name,
        params
    };

    const result = await axios.post(`${PARSE}/${CLOUD_FUNCTION}`, data);
    return result.data[RETURN_ARG_NAME];
}

export async function fetchAdminStats() {
    return defaultGetQuery(FETCH_ADMIN_STATS);
}

export async function buildAdminStats(date: string) {
    return cloudFunction('buildAdminStats', {date});
}

export async function getCustomerPortal(teamId: string) {
    return cloudFunction('getCustomerPortal', {teamId});
}

export async function getParseSingleTimeToken(userId: string) {
    return defaultGetQuery(SINGLE_TIME_TOKEN, {userId});
}

export async function fetchSubscriptionPlans() {
    return defaultGetQuery(SUBSCRIPTION_PLANS);
}

export async function fetchTeamMemberCounts(ids: string[], excludeRemoved: boolean = false) {
    return defaultGetQuery(TEAM_MEMBER_COUNTS, {ids: ids.join(','), excludeRemoved});
}

export async function fetchLastJibbleDates(teamIds: string[]) {
    return defaultGetQuery(LAST_JIBBLE_DATES, {teamIds: teamIds.join(',')});
}

export async function recreatePowerUpForTeam(teamId: string, powerUpId: string) {
    return defaultGetQuery(
        `${RECREATE_POWER_UP_FOR_TEAM}/${teamId}/${powerUpId}`
    );
}

export async function togglePowerUp(teamId: string, powerUpId: string) {
    return defaultGetQuery(`${POWER_UP_TOGGLE}/${teamId}/${powerUpId}`);
}

export async function fetchPowerUpsForTeam(teamId: string) {
    return defaultGetQuery(`${POWER_UPS_FOR_TEAM}/${teamId}`);
}

export async function fetchFreePowerUpsForTeam(teamId: string) {
    return defaultGetQuery(`${FREE_POWER_UPS_FOR_TEAM}/${teamId}`);
}

export async function fetchTeamInfo(teamId: string) {
    return defaultGetQuery(`${TEAM_INFO}/${teamId}`);
}

export async function fetchTeamsAndTeamCount(options: {searchTerm?: string, msTenantId?: string}) {
    const result = await defaultGetQuery(TEAMS, options);
    if (result[0].length) {
        return result;
    } else {
        if (options.searchTerm) {
            throw new Error(`No result for search term ${options.searchTerm}`);
        } else if (options.msTenantId) {
            throw new Error(`No result for tenant ID ${options.msTenantId}`);
        } else {
            throw new Error(`No result`);
        }
    }
}

export async function fetchUser(searchTerm: string) {
    const result = await defaultGetQuery(USERS, {searchTerm});
    if (result) {
        return result;
    } else {
        throw new Error(`No user with name/email/id ${searchTerm}`);
    }
}

export async function createChangeUserPasswordLink(userId: string) {
    return defaultPostQuery('createChangeUserPasswordLink', {userId});
}

export async function createLegacyUserInvitationLink(username: string) {
    return defaultPostQuery('createLegacyUserInvitationLink', {username});
}

export async function fetchTeamMembers(searchTerm: string) {
    const result = await defaultGetQuery(TEAM_MEMBERS, {searchTerm});
    if (result.length) {
        return result;
    } else {
        throw new Error(`No team member with name/email/id ${searchTerm}`);
    }
}

export async function fetchTeamMembersForUser(id: string) {
    const result = await defaultGetQuery(`${TEAM_MEMBERS_FOR_USER}/${id}`);
    if (result) {
        return result;
    } else {
        throw new Error(`No associated team members for user ${id}`);
    }
}

export async function updateParseObject(id: string, className: string, attributes: object) {
    return defaultPostQuery(UPDATE_PARSE_OBJECT, {id, className, attributes});
}

export async function deleteTeam(teamId: string) {
    return cloudFunction('destroyCompany', {teamId});
}

export async function deleteTeamMember(id: string) {
    return defaultDeleteQuery(`${DELETE_TEAM_MEMBER}/${id}`);
}

export async function deleteUser(userId: string) {
    return cloudFunction('destroyUser', {userId});
}

export async function saveTeamPermissions(attributes: object) {
    return defaultPostQuery(SAVE_TEAM_PERMISSIONS, attributes);
}

export async function reactivateSubscription(teamId: string, trialEndsAt?: Moment) {
    return cloudFunction('reactivateSubscriptionInternal', {teamId, trialEndsAt});
}

export async function updateSubscription(teamId: string, termEndsAt: Moment) {
    return cloudFunction('updateSubscriptionTermEndsAtInternal', {teamId, termEndsAt});
}

export async function removePaymentMethod(userId: string) {
    return cloudFunction('removePaymentMethod', {userId});
}

export async function resetSubscription(teamId: string) {
    return cloudFunction('resetSubscription', {teamId});
}

export async function createApproval(teamId: string, payPeriodStart: string, payPeriodEnd: string) {
    return cloudFunction('createApproval', {teamId, payPeriodStart, payPeriodEnd});
}

export async function resetApprovals(teamId: string) {
    return cloudFunction('resetApprovals', {teamId});
}

export async function initiateJibble2Migration(teamId: string) {
    return cloudFunction('initiateJibble2Migration', {teamId});
}

export async function slackApi(method: string, token: string) {
    const config = {
        withCredentials: false,
        params: {token}
    };

    const result = await axios.get(SLACK_API_URL + method, config);
    if (result.data.ok) {
        return result;
    } else {
        throw result.data;
    }
}

export async function slackTestWebhook(url: string, text: string) {
    const config: AxiosRequestConfig = {
        withCredentials: false,
        headers: {'Content-type': 'application/x-www-form-urlencoded'}
    };
    return axios.post(url, {text}, config);
}

export async function sendDebugNotification(params: object) {
    return cloudFunction('sendDebugNotification', params);
}

export async function switchToSubscriptionPlanType(teamId: string, planType: string, userAmount: number) {
    return cloudFunction('updateSubscriptionInternal', {teamId, planType, userAmount});
}

export async function updateSubscriptionAutoCollection(teamId: string, autoCollection: string) {
    return cloudFunction('updateSubscriptionInternal', {teamId, autoCollection});
}

export async function fetchEmailBounce(email: string) {
    return defaultGetQuery(EMAIL_BOUNCES, {email});
}

export async function deleteEmailBounce(email: string) {
    return defaultDeleteQuery(`${EMAIL_BOUNCES}/${encodeURIComponent(email.trim())}`);
}

export async function toggleTeamLock(teamId: string, lockedOut: boolean, lockoutReason?: string) {
    return defaultPostQuery('toggleTeamLock', {teamId, lockedOut, lockoutReason});
}
