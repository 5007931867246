import {Vue} from 'vue/types/vue';

export type LoopBackError = Error & {status: number};

export interface Credentials {
    email: string;
    password: string;
    totpCode?: string;
}

export interface EmailLogView extends Vue {
    deleteSuppression(email: string): Promise<void>;
}

export const RETURN_ARG_NAME = 'result';
export const ADMIN = 'admin';
export const AUTHENTICATED = '$authenticated';
export const VIEWER = 'viewer';
