









































import Vue from 'vue';
import {fetchEmailBounce, deleteEmailBounce} from '@/services/parseQueries';
import LoadingVue from '@/components/Loading.vue';
import ModalVue from '@/components/Modal.vue';
import SesEmailLog from './SesEmailLog.vue';
import {EmailLogView} from '../../../types';

export default Vue.extend({
    name: 'Emails',
    components: {ModalVue, LoadingVue, SesEmailLog},
    data() {
        return {
            searchQuery: '',
            loading: false,
            errorMessage: '',
            modalMessage: '',
            bounces: [],
            isSESEmailServiceProvider: process.env.VUE_APP_EMAIL_SERVICE_PROVIDER === 'ses',
            modalConfirmAction: undefined as Function | undefined,
            visibleModal: false
        }
    },
    methods: {
        async fetch() {
            this.errorMessage = '';
            this.loading = true;
            try {
                this.bounces = await fetchEmailBounce(this.searchQuery);
            } catch (e) {
                this.errorMessage = e.response ? e.response.data.error.message : e.message;
            } finally {
                this.loading = false;
            }
        },

        promptDeleteBounces() {
            this.showModal('Do you want to delete bounces?', this.deleteBounces);
        },

        async deleteBounces() {
            this.loading = true;
            try {
                await deleteEmailBounce(this.searchQuery);
                await this.fetch();
            } catch (e) {
                this.errorMessage = e.response ? e.response.data.error.message : e.message;
            } finally {
                this.loading = false;
            }
        },

        promptDeleteSuppression() {
            this.showModal('Do you want to remove address from suppression list?', this.deleteSuppression);
        },

        async deleteSuppression() {
            this.loading = true;
            try {
                const logView = this.getLogView();
                if (logView) {
                    this.errorMessage = '';
                    await logView.deleteSuppression(this.searchQuery);
                    alert(`Successfully removed ${this.searchQuery} from the suppression list`);
                }
            } catch (e) {
                this.errorMessage = e.response ? e.response.data.error.message : e.message;
            } finally {
                this.loading = false;
            }
        },

        getLogView(): EmailLogView | undefined {
            return this.$refs.logView as EmailLogView;
        },

        showModal(message: string, modalConfirmAction?: Function) {
            this.modalMessage = message;
            this.modalConfirmAction = modalConfirmAction;
            this.visibleModal = true;
        },

        confirmModal() {
            if (this.modalConfirmAction) {
                this.modalConfirmAction();
                this.modalConfirmAction = undefined;
            }
            this.visibleModal = false;
        },

         cancelModal() {
             this.modalConfirmAction = undefined;
             this.visibleModal = false;
         }
    }
});
