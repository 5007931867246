

























































import Vue from 'vue';
import BaseVue from './Base.vue';
import LoadingVue from '../components/Loading.vue';
import ModalVue from '../components/Modal.vue';
import logIntoTeam from '../helpers/logIntoTeam';
import {
    createChangeUserPasswordLink,
    createLegacyUserInvitationLink,
    deleteTeamMember,
    deleteUser,
    fetchTeamMembersForUser,
    fetchUser
} from '@/services/parseQueries';

export default Vue.extend({
    name: 'Users',
    extends: BaseVue,
    components: {LoadingVue, ModalVue},
    data() {
        return {
            searchTerm: '',
            user: null as any,
            loading: false,
            errorMessage: '',
            teamMembers: [] as any[],
            modalConfirmAction: () => {},
            showModal: false,
            modalHeader: '',
            placeholderText: '',
            teamMemberId: '',
            leaveTeamName: '',
            modalShowInput: false,
            modalInputValue: '',
            modalInputError: '',
            modalLoading: false,
            inputInvalid: false,
            actionResult: '',
            actionError: false,
            showActionResult: false
        };
    },
    mounted() {
        if (this.$route.query.q) {
            this.searchTerm = this.$route.query.q.toString();
            this.searchUser();
        }
    },
    methods: {
        search() {
            this.searchTerm = this.searchTerm.trim();
            if (!this.searchTerm) {
                return;
            }
            this.$router.replace({path: 'users', query: {q: this.searchTerm}});
            this.searchUser();
        },

        async searchUser() {
            this.errorMessage = '';
            try {
                this.loading = true;
                const user = await fetchUser(this.searchTerm);
                this.user = user;
                this.teamMembers = await fetchTeamMembersForUser(user.objectId);
            } catch (error) {
                this.errorMessage = error.response ? error.response.data.error.message : error.message;
                this.user = null;
                this.teamMembers = [];
            } finally {
                this.loading = false;
            }
        },

        async createChangePasswordLink() {
            const message = `Copy the link below and send it to the user. ` +
                `They will be able to update their password themselves`;
            const link = await createChangeUserPasswordLink(this.user.objectId);
            window.prompt(message, link);
        },

        promptDeleteUser() {
            if (this.teamMembers.length) {
                const teamNames = this.teamMembers.map(teamMember => teamMember.company.name).join('\n');
                this.actionResult = `Delete user from teams first, then delete user.\nUser is a
                    member of the following teams:\n\n${teamNames}`;
                this.actionError = true;
                this.showActionResult = true;
                return;
            }

            this.modalHeader = `Are you sure you want to delete user ${this.user.email}?`;
            this.modalShowInput = false;
            this.modalLoading = false;
            this.modalConfirmAction = this.deleteUser;
            this.actionError = false;
            this.showModal = true;
        },

        promptLeaveTeam(teamMemberId: string, teamName: string) {
            this.teamMemberId = teamMemberId;
            this.leaveTeamName = teamName;
            this.modalHeader = 'Are you sure you want this user to leave a team?';
            this.placeholderText = 'Type in team name';
            this.modalShowInput = true;
            this.modalInputValue = '';
            this.modalInputError = 'Invalid team name';
            this.modalLoading = false;
            this.modalConfirmAction = this.leaveTeam;
            this.actionError = false;
            this.showModal = true;
        },

        async leaveTeam() {
            this.inputInvalid = false;
            if (this.leaveTeamName !== this.modalInputValue) {
                this.inputInvalid = true;
                return;
            }

            try {
                this.modalLoading = true;
                await deleteTeamMember(this.teamMemberId);
                this.actionResult = 'User left team';
                // this.searchUser();
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.showModal = false;
                this.showActionResult = true;

                this.teamMemberId = '';
                this.leaveTeamName = '';
                this.modalInputValue = '';
                this.modalLoading = false;
            }
        },

        async deleteUser() {
            try {
                this.modalLoading = true;
                await deleteUser(this.user.objectId);
                this.actionResult = 'Successfully deleted user';
                this.user = null;
                this.teamMembers = [];
                this.searchTerm = '';
            } catch (error) {
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            } finally {
                this.modalLoading = false;
                this.showModal = false;
                this.showActionResult = true;
            }
        },

        async parseLogin(teamId: string): Promise<void> {
            try {
                await logIntoTeam(this.user.objectId, teamId);
            } catch (error) {
                this.errorMessage = error.message;
            }
        },

        async createInvitationLink() {
            const username = window.prompt('Enter email address or phone number for user to invite', '');
            if (!username) {
                return;
            }
            try {
                const link = await createLegacyUserInvitationLink(username);
                const message = `Copy the link below and send it to the user. ` +
                    `They will be able to sign up. Link is valid for 3 days`;
                window.prompt(message, link);
            } catch (error) {
                window.alert(`Error occurred: ${error.message}`);
            }
        }
    }
});
