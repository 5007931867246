






import Vue from 'vue'
import NavigationVue from './Navigation.vue';

export default Vue.extend({
    name: 'Header',
    components: {NavigationVue}
})
