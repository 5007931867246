import Vue from 'vue';
import Vuex from 'vuex';
import {login, logout, refreshToken} from '../services/authentication';
import {
    LOGGED_IN,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    LOGGED_OUT,
    REFRESH_TOKEN_REQUEST,
    TOKEN_EXPIRED,
    TOKEN_VALID,
    USER_EMAIL
} from './types';
import {Credentials} from 'types';

Vue.use(Vuex);

const state = {
    isLoggedIn: false,
    roles: [],
    tokenExpired: false,
    require2fa: process.env.VUE_APP_REQUIRE_2FA === 'true'
};

const getters = {
    isLoggedIn: (store: any) => store.isLoggedIn,
    roles: (store: any) => store.roles
};

const actions = {
    [LOGIN_REQUEST]: async ({commit}: {commit: Function}, credentials: Credentials) => {
        const roles = await login(credentials);
        commit(LOGGED_IN, roles);
        commit(TOKEN_VALID);
        localStorage.setItem(USER_EMAIL, credentials.email);
    },

    [REFRESH_TOKEN_REQUEST]: async ({commit}: {commit: Function}) => {
        try {
            const {data} = await refreshToken();
            commit(LOGGED_IN, data.roles);
        } catch (error) {}
    },

    [TOKEN_EXPIRED]: ({commit}: {commit: Function}) => {
        commit(LOGGED_OUT);
        commit(TOKEN_EXPIRED);
    },

    [LOGOUT_REQUEST]: async ({commit}: {commit: Function}) => {
        try {
            await logout();
        } finally {
            commit(LOGGED_OUT);
        }
    }
};

const mutations = {
    [LOGGED_IN]: (store: any, roles: string) => {
        store.isLoggedIn = true;
        store.roles = roles;
    },

    [TOKEN_EXPIRED]: (store: any) => {
        store.tokenExpired = true;
    },

    [TOKEN_VALID]: (store: any) => {
        store.tokenExpired = false;
    },

    [LOGGED_OUT]: (store: any) => {
        store.isLoggedIn = false;
        store.roles = [];
    }
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});
