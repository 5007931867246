
















































































import Vue from 'vue';
import BaseVue from './Base.vue';
import LoadingVue from '../components/Loading.vue';
import ModalVue from '../components/Modal.vue';
import {
    fetchPowerUpsForTeam,
    slackApi,
    slackTestWebhook,
    togglePowerUp,
    recreatePowerUpForTeam,
    fetchFreePowerUpsForTeam,
    updateParseObject
} from '../services/parseQueries';

export default Vue.extend({
    name: 'TeamPowerUps',
    extends: BaseVue,
    components: {LoadingVue, ModalVue},
    data() {
        return {
            errorMessage: '',
            powerUps: [] as any,
            allPowerUps: [] as any,
            freePowerups: [] as any,
            enabledPowerUps: [] as any,
            companyPermissionsFreePowerUps: [] as any,
            modalInputValue: '',
            modalInputError: '',
            modalInputInvalid: false,
            modalConfirmAction: () => {},
            modalLoading: false,
            actionResult: '',
            actionError: false,
            showActionResult: false,
            showPowerUpsModal: false
        };
    },
    async mounted() {
        try {
            this.allPowerUps = [
                { powerUpId: "activity", name: "Activity Tracking" },
                { powerUpId: "billing", name: "Client Billing" },
                { powerUpId: "collaborators", name: "Collaboration" },
                { powerUpId: "export", name: "Export" },
                { powerUpId: "facerecognition", name: "Facial Recognition" },
                { powerUpId: "location", name: "Geolocation" },
                { powerUpId: "msteams", name: "Microsoft Teams" },
                { powerUpId: "passcode", name: "Kiosk+" },
                { powerUpId: "policies", name: "Policies" },
                { powerUpId: "productivity", name: "Desktop Productivity" },
                { powerUpId: "slack", name: "Slack" },
                { powerUpId: "workschedule", name: "Work Schedules" },
                { powerUpId: "payrollpandaintegration", name: "PayrollPanda" },
            ];
            await this.refreshPowerUpsList();
        } catch (error) {
            this.errorMessage = error.response.data.error.message;
        }
    },

    methods: {
        async refreshPowerUpsList() {
            try {
                this.companyPermissionsFreePowerUps = await fetchFreePowerUpsForTeam(this.$route.params.id);
                this.powerUps = await fetchPowerUpsForTeam(this.$route.params.id);
                this.enabledPowerUps = (this.powerUps || []).map((pup: any) => pup.powerUpId) || [];
                this.freePowerups = this.allPowerUps.filter((pup: any) => !this.enabledPowerUps.includes(pup.powerUpId));
            } catch (error) {
                console.log("error", error);
            }
        },
        async slackTestIntegration() {
            const powerUp = this.powerUps.find((p: any) => p.powerUpId === 'slack');
            const providerData = powerUp.providerData || {};

            try {
                await slackApi('auth.test', providerData.access_token);
                this.actionResult = 'Integration is successful';
                this.actionError = false;
            } catch (error) {
                this.actionResult = error.error || error.message;
                this.actionError = true;
            } finally {
                this.showActionResult = true;
            }
        },

        promptListPrivatePowerUps() {
            this.modalInputError = "";
            this.modalInputInvalid = false;
            this.modalConfirmAction = this.addPrivatePowerUp;
            this.actionError = false;
            this.showPowerUpsModal = true;
        },

        async togglePowerUpForTeam(powerUpId: string) {
            try {
                await togglePowerUp(this.$route.params.id, powerUpId);
                await this.refreshPowerUpsList();
            } catch (e) {
                console.log(e)
                window.alert(`Error occurred: ${e.message}`);
            }
        },

        async addPrivatePowerUp() {
            try {
                await recreatePowerUpForTeam(this.$route.params.id, this.modalInputValue);
                this.refreshPowerUpsList();
            } catch (e) {
                window.alert(`Error occurred: ${e.message}`);
            }
            this.showPowerUpsModal = false;
        },

        async promptSlackTestWebhook() {
            const value = window.prompt('Send message via incoming webhook', 'Hello, this is a test message from Jibble!');
            if (value) {
                await this.slackTestWebhook(value);
            }
        },

        async slackTestWebhook(message: string) {
            const powerUp = this.powerUps.find((p: any) => p.powerUpId === 'slack');
            const providerData = powerUp.providerData || {};
            const providerWebhookData = providerData.incoming_webhook || {};

            try {
                await slackTestWebhook(providerWebhookData.url, message);
                window.alert('Successfully sent message');
            } catch (error) {
                window.alert(`Error occurred: ${error.response.data.error.message}`);
                this.actionResult = error.response.data.error.message;
                this.actionError = true;
            }
        },

        async promptDeleteSlackIntegration(id: string) {
            const value = window.prompt('Delete Slack integration [yes/no]?', '');
            if (value && value.toLowerCase() === 'yes') {
                await this.deleteBotIntegration(id);
            }
        },

        async promptDeleteMSTeamsIntegration(id: string) {
            const value = window.prompt('Delete MS Teams integration [yes/no]?', '');
            if (value && value.toLowerCase() === 'yes') {
                await this.deleteBotIntegration(id);
            }
        },

        async deleteBotIntegration(id: string) {
            const attrs = {
                isEnabled: false,
                settings: null,
                providerData: null,
                publicProviderData: null
            };
            try {
                await updateParseObject(id, 'PowerUp', attrs);
                await this.refreshPowerUpsList();
            } catch (e) {
                window.alert(`Error occurred: ${e.message}`);
            }
        }
    }
});
